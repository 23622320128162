import React from "react";
import SideNav from "../Nav/SideNav";
import IconArrow from "@material-ui/icons/ArrowForwardIos"
import Spinner from "../Shared/Spinner";

const PostPageControls = ({ postId, user, updateUser, isFetching, showEdit, showDelete, hasFlaggedPost, isAdmin, isAuthor, isModerator, isFavourite, isLiked, isPinned, isPatchNotes, isOpenToComments, isHidden, functions: { handleComment, handleEdit, handleDelete, handleHidePost, handleFlagPost, handleFavourites, handleVote, handlePin } }) => {

    if (isFetching) return <SideNav user={user} updateUser={updateUser}> <Spinner loading={isFetching} /> </SideNav>;

    if (!postId) return <SideNav user={user} updateUser={updateUser}></SideNav>
    return <SideNav user={user} updateUser={updateUser}>
        {user?.data?.id ? <div>
            {/* Normal author user Options, admins can edit or delete, but these options will be shown below */}
            {showEdit && !isAdmin && !isModerator ? <div className="control_text" onClick={handleEdit}><p>Edit</p></div> : null}
            {showDelete && !isAdmin && !isModerator ? <div className="control_text" onClick={handleDelete}><p>Delete</p></div> : null}
            {(showEdit || showDelete) && !isAdmin && !isModerator ? <><br /><hr /><br /></> : null}
            {/* Normal user options */}
            <div className="control_text" onClick={() => handleVote(isLiked)}><p>{!isLiked ? "Upvote" : "Downvote"}</p></div>
            <div className="control_text" onClick={() => handleFavourites(!isFavourite)}><p>{isFavourite ? "UnFavorite" : "Add Favorite"}</p></div>
            {!isAuthor && !isPatchNotes ? <div className="control_text" onClick={() => handleFlagPost(!hasFlaggedPost)}><p>{!hasFlaggedPost ? "Flag Post" : "Unflag Post"}</p></div> : null}
            <div className="control_text" onClick={handleHidePost}><p>{isHidden ? "Unhide" : "Hide"} {isPatchNotes ? "Patch note" : "Post"}</p></div>
            <div className={isOpenToComments ? "control_text" : "control_text_disabled"} onClick={isOpenToComments ? handleComment : () => { }}><p>Write comment <IconArrow></IconArrow></p> </div>
            {/* Admin Options */}
            {(showEdit || showDelete) && (isAdmin || isModerator) ? <><br /><hr /><br /></> : null}
            {showDelete && (isAdmin) ? <div className="control_text_admin" onClick={() => handlePin(!isPinned)}><p>{!isPinned ? "Pin " : "Unpin "} {isPatchNotes ? "Patch note" : "Post"}</p></div> : null}
            {showEdit && (isAdmin || isModerator) ? <div className="control_text_admin" onClick={handleEdit}><p>Edit</p></div> : null}
            {showDelete && (isAdmin || isModerator) ? <div className="control_text_admin" onClick={handleDelete}><p>Delete</p></div> : null}
        </div> : null}
    </SideNav>;
}

export default PostPageControls;