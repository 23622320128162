import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { pageQuery } from "../../util/misc";
import SideNav from "../Nav/SideNav";
import { mobileRes } from '../Nav/SideNav';
import SearchIcon from '@material-ui/icons/Search';
import MobileControlDrawer from "../PostList/MobileControlDrawer"
import internal_fetch from "../../util/local-api";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Select from 'react-select';
import "../PostList/PostControls.css";
const { parseQuery, defaultsAdmin, constructQueryURL, sorts, dates } = pageQuery;

const colourStyles = {
    control: styles => ({
        ...styles, backgroundColor: '#3f3e3e5c', minHeight: "0px", padding: "0px", width: "250px", color: "white", boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: "#3f3e3e5c",
            color: "white",
            cursor: isDisabled ? 'not-allowed' : 'default',

        };
    },
    dropdownIndicator: (styles) => {
        return {
            ...styles,
            padding: "0px",
            paddingRight: "5px"
        }
    },
    menu: (styles) => {
        return {
            ...styles,
            width: "250px",
            backgroundColor: "#3f3e3e",
            boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
        }
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
            borderRadius: "15px",
            padding: "3px 10px 3px 10px",
            backgroundColor: "#e67e22",
        };
    },
    singleValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
    placeholder: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        ':hover': {
            color: 'white',
        },
    }),
};

const PatchNotesControls = ({ windowState, user, isAdmin, updateUser, isFetching, selectedPage, functions: { updatePosts, updateIsFetching, updateTotalPages, updateSelectedPage } }) => {


    const history = useHistory();
    const [query, setQuery] = useState(parseQuery())
    const [allVersions, setVersions] = useState([]);
    const [tempSearch, updateSearch] = useState("");
    const [previousQuery, updatePreviousQuery] = useState("");
    const [mobileDrawerExpanded, updateMobileDrawerExpanded] = useState(false);
    const [fetchOptions, updateFetchOptions] = useState({})
    const [totalPosts, _updateTotalPosts] = useState(0);
    const [totalUpvotes, _updateTotalUpvotes] = useState(0);
    const [totalComments, _updateTotalComments] = useState(0);

    const mainRef = useRef(null);
    const textareaRef = useRef(null);

    const versions_data = [{ text: "All Versions", value: "" },
        { text: "Early Access", value: "early" },
        { text: "Experimental", value: "experimental" }
    ]

    const updateInnerValue = (name) => (value) => {
        let { ...tmp } = fetchOptions;
        if (name === "sort" && value === 2) {
            tmp = { ...defaultsAdmin };
            tmp.sort = 2;
        }
        else tmp[name] = value;
        if (name === "search" && value && tmp.date === defaultsAdmin.date) {
            tmp.date = dates[5]
        }
        localStorage.setItem("fetch_options_admin", JSON.stringify(tmp));

        updateFetchOptions(tmp);
        updateSelectedPage(1);
        // updatePage(0)
    }

    const updateUrl = () => {
        var url = constructQueryURL(fetchOptions)
        if (!url.includes("patchnotes")) {
            url = `/patchnotes${url}`
        }
        //???????????????????
        if (previousQuery !== url) {
            history.push(url)
            updatePreviousQuery(url);
        }
    }

    const fetchPosts = async (page, cb) => {
        if (isFetching || !fetchOptions.date) return
        updateIsFetching(true);

        const data = await internal_fetch.all_patch_notes(fetchOptions.date.value, fetchOptions.search, fetchOptions.category, sorts[fetchOptions.sort].sort, fetchOptions.status, fetchOptions.versions, fetchOptions.answered, fetchOptions.showHidden, page * fetchOptions.limit, fetchOptions.limit);
        if (data && data.allPatchNotes) {
            console.log(data.allPatchNotes)
            cb(data.allPatchNotes.posts);

            if (data && data.allPatchNotes?.totalDocs) {
                _updateTotalPosts(data.allPatchNotes.totalDocs)
            }
            else {
                _updateTotalPosts(0)
            }

            if (data && data.allPatchNotes?.totalUpvotes) {
                _updateTotalUpvotes(data.allPatchNotes.totalUpvotes)
            }

            if (data && data.allPatchNotes?.totalComments) {
                _updateTotalComments(data.allPatchNotes.totalComments)
            }

            updateTotalPages(data.allPatchNotes.totalPages)
        }
        else {
            _updateTotalPosts(0)
            cb([])
        }

        updateIsFetching(false);
    }

    useEffect(() => {
        updateFetchOptions({ ...fetchOptions, page: selectedPage })
    }, [selectedPage]); // eslint-disable-line react-hooks/exhaustive-deps

    history.listen((a, b, c) => {
        const newQuery = parseQuery();
        if (JSON.stringify(query) !== JSON.stringify(newQuery)) {
            setQuery(newQuery)
        }
    });

    useEffect(() => {
        if (windowState.windowWidth >= mobileRes) {
            updateMobileDrawerExpanded(false)
        }
    }, [windowState])

    useEffect(() => {
        let search = defaultsAdmin.search;
        if (Object.entries(query).length > 0) {

            query.showHidden = (query.showHidden === "true" || query.showHidden === true)

            updateFetchOptions({
                sort: query.sort != null ? query.sort : defaultsAdmin.sort,
                limit: query.limit != null ? query.limit : defaultsAdmin.limit,
                search: query.search != null ? query.search : defaultsAdmin.search,
                category: query.category != null ? query.category : defaultsAdmin.category,
                date: query.date != null ? query.date : defaultsAdmin.date,
                status: query.status != null ? query.status : defaultsAdmin.status,
                versions: query.versions != null ? query.versions : defaultsAdmin.version_number,
                answered: query.answered != null ? query.answered : defaultsAdmin.answered,
                showHidden: query.showHidden != null ? query.showHidden : defaultsAdmin.showHidden
            })
            search = query.search ? query.search : defaultsAdmin.search;
        }
        else if (localStorage.getItem("fetch_options_admin")) {
            const options = JSON.parse(localStorage.getItem("fetch_options_admin"));
            search = options.search ? options.search : defaultsAdmin.search;

            updateFetchOptions(options);
        }
        else {
            updateFetchOptions({
                sort: query.sort != null ? query.sort : defaultsAdmin.sort,
                limit: query.limit != null ? query.limit : defaultsAdmin.limit,
                search: query.search != null ? query.search : defaultsAdmin.search,
                category: query.category != null ? query.category : defaultsAdmin.category,
                date: query.date != null ? query.date : defaultsAdmin.date,
                status: query.status != null ? query.status : defaultsAdmin.status,
                versions: query.versions != null ? query.versions : defaultsAdmin.version_number,
                answered: query.answered != null ? query.answered : defaultsAdmin.answered,
                showHidden: query.showHidden != null ? query.showHidden : defaultsAdmin.showHidden
            })
            search = query.search ? query.search : defaultsAdmin.search;
        }
        updateSearch(search);
    }, [JSON.stringify(query)]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setVersions(["early", "experimental"]);
    }, [])

    useEffect(() => {
        fetchPosts(selectedPage - 1, updatePosts);
        updateUrl();
    }, [JSON.stringify(fetchOptions)]); // eslint-disable-line react-hooks/exhaustive-deps

    const isOnHot = false;// fetchOptions.sort === 2;

    useEffect(() => {
        var element = document.getElementById('auto-grow-textarea');

        if (!element) {
            element = textareaRef.current
            if (!element) return
        }

        if (`${tempSearch}`.length < 20) {
            element.style.height = '32px';
        }
        else {
            element.style.height = 'auto';
            element.style.height = (element.scrollHeight) + 'px'; // 10px for padding
        }
    }, [tempSearch]);

    const handleChange = (event) => {
        updateSearch(event.target.value);
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (Object.entries(fetchOptions).length < 1)
        return null;
    return (<div className="post_controls">

        <div className="post_controls_upper" style={mobileDrawerExpanded ? isOnHot ? { height: "45px" } : { height: "90px" } : windowState.windowWidth < 884 ? { height: "20px" } : {}}>
            <MobileControlDrawer expanded={mobileDrawerExpanded} toggle={() => updateMobileDrawerExpanded(!mobileDrawerExpanded)}>

                {isOnHot ? null :
                    <div className="mobile_drawer_container" >
                        <h1 style={{ color: "#e69344", fontWeight: "600", margin: "5px" }}>Search Filters:</h1>

                        <select disabled={isFetching || isOnHot} onChange={(e) => updateInnerValue("versions")(e.target.value)}>
                            <option value="">All Versions</option>
                            {allVersions.map((version, i) => <option selected={version === fetchOptions.versions} value={version} key={i}>{capitalizeFirstLetter(version)}</option>)}
                        </select>
                    </div>}

            </MobileControlDrawer>
            <>
                <SideNav user={user} updateUser={updateUser}>
                    <div className="post_controls_lower">
                        <div className="post_searchbar">
                            <textarea id="auto-grow-textarea" className="grow-input" ref={textareaRef} disabled={isFetching} maxLength={100} type="text" placeholder="Search..." value={tempSearch} onKeyDown={(e) => e.key === "Enter" ? updateInnerValue("search")(tempSearch) : null} onChange={(e) => handleChange(e)} />
                            <SearchIcon onClick={() => mainRef.current.focus()} style={{ fontSize: "24px" }} />
                        </div>
                    </div>

                    <Select
                        value={!fetchOptions.versions ? { label: versions_data[0].text, value: versions_data[0].value } : versions_data.filter(i => i.value === fetchOptions.versions).map((e) => { return { label: e.text, value: e.value } })}
                        options={versions_data.map((version) => { return { label: version.text, value: version.value } })}
                        className="basic-multi-select"
                        styles={colourStyles}
                        onChange={(e) => {
                            console.log(e.value)
                            console.log(versions_data.filter(i => i.value === e.value))
                            updateInnerValue("versions")(e.value)
                        }
                        }
                    />

                    <FormControlLabel
                        control={
                            <Checkbox checked={fetchOptions.showHidden} onChange={(e) => {
                                updateInnerValue("showHidden")(e.target.checked)
                            }} value={fetchOptions.showHidden} disabled={false} />
                        }
                        label="Show Hidden Notes"
                    />

                    {
                        totalPosts > 0 && isAdmin && !isFetching ? <div style={{ opacity: "0.7", marginLeft: "18px", marginBottom: "18px", flexGrow: "2", display: "flex", alignItems: "end", }}>
                            Search result summary
                            <br />
                            <br />
                            Total Posts: {totalPosts}
                            <br /><br />
                            Total Votes: {totalUpvotes}
                            <br /><br />
                            Total Comments: {totalComments}
                        </div> : <></>
                    }
                </SideNav>
            </>
        </div>
        {
            isOnHot ? null :
                <div className="post_controls_lower">
                    <div className="post_searchbar">
                        <input ref={mainRef} disabled={isFetching} maxLength={100} type="text" placeholder="Search..." value={tempSearch} onKeyDown={(e) => e.key === "Enter" ? updateInnerValue("search")(tempSearch) : null} onChange={(e) => updateSearch(e.target.value)} />
                        <SearchIcon onClick={() => mainRef.current.focus()} style={{ fontSize: "24px" }} />
                    </div>
                </div>
        }
    </div>);
}

export default PatchNotesControls;

export const mobileResValue = mobileRes;
